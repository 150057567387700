import React from "react";

const Business = React.lazy(() => import("./pages/Business/index"));
const PageAboutUs = React.lazy(() => import("./pages/Pages/PageAboutUs"));
const PageContact = React.lazy(() => import("./pages/Pages/Contact/PageContactOne"));
const PageTerms = React.lazy(() => import("./pages/Pages/Utility/PageTerms"));
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));

const routes = [
  { path: "/", component: Business, isWithoutLayout: false, isTopbarDark: true, exact: true },
  { path: "/about", component: PageAboutUs, isTopbarDark: true },
  { path: "/contact", component: PageContact, isTopbarDark: true },
  { path: "/terms", component: PageTerms, isTopbarDark: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
